import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ShippingCard.module.scss'
import { ShippingStates } from 'services/shippings'
import { Card, ShippingBadge, Logo } from 'ui'
import {
  ShippingCardActions,
  ShippingCardDescription,
  ShippingCardHeader,
  ShippingCardInfos,
} from './components'

export const ShippingCard = props => {
  const [opened, setOpened] = useState(false)
  const { shipping } = props
  const modifiers = opened ? styles._opened : ''
  const displayInfos = ![ShippingStates.forthcoming.value].includes(
    shipping.status
  )

  return (
    <Card padding={false} light>
      <div className={`${styles.card} ${modifiers}`}>
        <div className={styles.badge}>
          <ShippingBadge status={shipping.status} />
        </div>

        <div className={styles.picture}>
          {shipping.image && (
            <img className={styles.pictureSource} src={shipping.image} alt="" />
          )}
          {!shipping.image && (
            <div className={styles.pictureDefault}>
              <Logo />
            </div>
          )}
        </div>

        <div className={styles.container}>
          <ShippingCardHeader
            {...props}
            showOpenButton={displayInfos}
            opened={opened}
            onShowClick={() => setOpened(!opened)}
          />

          {!opened && <ShippingCardDescription {...props} />}

          <div className={styles.footer}>
            {displayInfos && (
              <div className={styles.footerInfos}>
                <ShippingCardInfos {...props} />
              </div>
            )}
            <div className={styles.footerActions}>
              {<ShippingCardActions {...props} />}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

ShippingCard.propTypes = {
  shipping: PropTypes.shape({
    allowReprint: PropTypes.bool,
    address: PropTypes.string,
    cancelReason: PropTypes.string,
    createdAt: PropTypes.string,
    expectedFrom: PropTypes.string,
    sentAt: PropTypes.string,
    acceptedAt: PropTypes.string,
    receivedAt: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    company: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    infos: PropTypes.string,
    isLate: PropTypes.bool,
    lastname: PropTypes.string,
    markedReceived: PropTypes.bool,
    phone: PropTypes.string,
    price: PropTypes.number,
    origin: PropTypes.string,
    status: PropTypes.oneOf([
      'accepted',
      'canceled',
      'delivered',
      'forthcoming',
      'received',
      'sent',
    ]),
    trackingRef: PropTypes.string,
    shipperName: PropTypes.string,
    shipperUrl: PropTypes.string,
    weight: PropTypes.number,
  }).isRequired,
  shippers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    })
  ).isRequired,
  supplier: PropTypes.shape({
    name: PropTypes.string,
    trackingCodeRequired: PropTypes.bool,
    useColissimo: PropTypes.bool,
  }).isRequired,
  onAccept: PropTypes.func,
  onSent: PropTypes.func,
  onTrackingAdd: PropTypes.func,
  onTrackingPrint: PropTypes.func,
}
