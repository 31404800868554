import React from 'react'
import styles from './ShippingCardActions.module.scss'
import { ShippingStates } from 'services/shippings'
import { Button, Selectbox, TextField, TextFieldButton } from 'ui'

export class ShippingCardActions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRef: null,
      selectedShipper: null,
      trackingOpened: false,
      weightOpened: false,
    }
  }
  componentDidMount() {
    const { supplier, shippers } = this.props
    if (shippers.length === 1 && !supplier.useUnknownShipper) {
      this.setState({ selectedShipper: shippers[0].id.toString() })
    }
    if (shippers.length === 0 && supplier.useUnknownShipper) {
      this.setState({ selectedShipper: '0' })
    }
  }

  onTrackingOpenClick = () => {
    this.setState({ trackingOpened: !this.state.trackingOpened })
  }

  onTrackingRefSubmit = () => {
    const { selectedShipper, selectedRef } = this.state
    const shipperId =
      selectedShipper === null || selectedShipper === '0'
        ? null
        : parseInt(selectedShipper)
    this.props.onTrackingAdd(this.props.shipping.id, selectedRef, shipperId)
  }

  onColissimoOpenClick = () => {
    this.setState({ weightOpened: !this.state.weightOpened })
  }

  onColissimoSubmit = value => {
    this.props.onTrackingPrint(this.props.shipping.id, value)
  }

  onColissimoReprintClick = () => {
    this.props.onTrackingPrint(
      this.props.shipping.id,
      this.props.shipping.weight
    )
  }

  render() {
    const actions = []
    this.appendActionAccept(actions)
    this.appendActionColissimoReprint(actions)
    this.appendActionTrackingUpdate(actions)

    if (this.props.shipping.trackingRef) {
      this.appendActionTrackingSubmit(actions)
    }

    this.appendActionTrackingSend(actions)
    this.appendActionColissimoPrint(actions)
    this.appendActionColissimoSubmit(actions)
    this.appendActionTrackingAdd(actions)

    if (!this.props.shipping.trackingRef) {
      this.appendActionTrackingSubmit(actions)
    }

    return (
      <div>
        {actions.length > 0 && (
          <div className={styles.actions}>
            {actions.map((action, index) => (
              <div className={styles.action} key={index}>
                {action}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  appendActionAccept(actions) {
    const { shipping, onAccept } = this.props
    const hasAccept = shipping.status === ShippingStates.received.value

    if (hasAccept) {
      actions.push(
        <Button primary onClick={() => onAccept && onAccept(shipping.id)}>
          Accepter
        </Button>
      )
    }
    return actions
  }

  appendActionColissimoPrint(actions) {
    const { shipping, supplier } = this.props
    const hasColissimo =
      shipping.status === ShippingStates.accepted.value &&
      !this.state.trackingOpened &&
      !shipping.trackingRef &&
      !this.state.weightOpened &&
      supplier.useColissimo

    if (hasColissimo) {
      actions.push(
        <Button primary onClick={this.onColissimoOpenClick}>
          Imprimer le bordereau
        </Button>
      )
    }
    return actions
  }

  appendActionColissimoReprint(actions) {
    const { shipping, supplier } = this.props
    const hasColissimo =
      shipping.status === ShippingStates.accepted.value &&
      !this.state.trackingOpened &&
      shipping.trackingRef &&
      shipping.allowReprint &&
      shipping.weight > 0 &&
      !this.state.weightOpened &&
      supplier.useColissimo

    if (hasColissimo) {
      actions.push(
        <Button onClick={this.onColissimoReprintClick}>
          Réimprimer le bordereau
        </Button>
      )
    }
    return actions
  }

  appendActionColissimoSubmit(actions) {
    const { shipping, supplier } = this.props
    const hasColissimoSubmit =
      shipping.status === ShippingStates.accepted.value &&
      !this.state.trackingOpened &&
      !shipping.trackingRef &&
      this.state.weightOpened &&
      supplier.useColissimo

    if (hasColissimoSubmit) {
      actions.push(
        <TextFieldButton
          label={'Poids (kg) du colis'}
          id={'Weight'}
          focused={true}
          type={'number'}
          min={0}
          max={30}
          step={0.001}
          width={180}
          required={true}
          primary
          onSubmit={this.onColissimoSubmit}
        >
          OK
        </TextFieldButton>
      )
    }
    return actions
  }

  appendActionTrackingAdd(actions) {
    const { shipping, supplier } = this.props
    const hasTrackingAdd =
      shipping.status === ShippingStates.accepted.value &&
      !this.state.trackingOpened &&
      !shipping.trackingRef &&
      !supplier.useColissimo

    if (hasTrackingAdd) {
      actions.push(
        <Button primary onClick={this.onTrackingOpenClick}>
          Ajouter n° suivi
        </Button>
      )
    }
    return actions
  }

  appendActionTrackingUpdate(actions) {
    const { shipping, supplier } = this.props
    const hasTrackingUpdate =
      shipping.status === ShippingStates.accepted.value &&
      !this.state.trackingOpened &&
      shipping.trackingRef &&
      !supplier.useColissimo

    if (hasTrackingUpdate) {
      actions.push(
        <Button onClick={this.onTrackingOpenClick}>Modifier n° suivi</Button>
      )
    }
    return actions
  }

  appendActionTrackingSubmit(actions) {
    const { shipping, shippers, supplier } = this.props
    const { selectedShipper, selectedRef } = this.state

    const hasTrackingSubmit =
      shipping.status === ShippingStates.accepted.value &&
      this.state.trackingOpened

    if (!hasTrackingSubmit) return actions

    let shipperList = shippers.map(s => ({
      id: s.id.toString(),
      value: s.value,
    }))
    if (supplier.useUnknownShipper) {
      shipperList.push({ id: '0', value: 'Autre expéditeur' })
    }

    actions.push(
      <Selectbox
        id="TrackingShipper"
        label="Transporteur"
        onChange={selectedShipper => this.setState({ selectedShipper })}
        values={shipperList}
        initialValue={selectedShipper ? selectedShipper : null}
        disabled={shipperList.length === 1}
        width={250}
      />
    )

    actions.push(
      <div className={styles.tracking}>
        <TextField
          label={'N° de suivi'}
          id={'TrackingRef'}
          focused={true}
          required={true}
          primary
          onChange={selectedRef => this.setState({ selectedRef })}
        />
        <div className={styles.trackingSubmit}>
          <Button
            primary
            disabled={!selectedShipper || !selectedRef}
            onClick={this.onTrackingRefSubmit}
          >
            OK
          </Button>
        </div>
      </div>
    )

    return actions
  }

  appendActionTrackingSend(actions) {
    const { shipping, supplier, onSent } = this.props

    const hasSend =
      shipping.status === ShippingStates.accepted.value &&
      !this.state.trackingOpened
    const isSendDisabled =
      shipping.status === ShippingStates.accepted.value &&
      supplier.trackingRequired &&
      !shipping.trackingRef

    const hasTrackingButton =
      shipping.status === ShippingStates.accepted.value &&
      !this.state.trackingOpened &&
      !shipping.trackingRef &&
      !this.state.weightOpened

    if (hasSend) {
      actions.push(
        <Button
          primary={!hasTrackingButton}
          onClick={() => onSent && onSent(shipping.id)}
          disabled={isSendDisabled}
        >
          Marquer Expédié
        </Button>
      )
    }
    return actions
  }
}
