import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextField.module.scss'
import { IconClose } from 'ui'

const PATTERN_PHONE = '[0-9]{10}'
const PATTERN_EMAIL =
  '^([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+|\\x22([^\\x0d\\x22\\x5c\\x80-\\xff]|\\x5c[\\x00-\\x7f])*\\x22)(\\x2e([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+|\\x22([^\\x0d\\x22\\x5c\\x80-\\xff]|\\x5c[\\x00-\\x7f])*\\x22))*\\x40([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+|\\x5b([^\\x0d\\x5b-\\x5d\\x80-\\xff]|\\x5c[\\x00-\\x7f])*\\x5d)(\\x2e([^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+|\\x5b([^\\x0d\\x5b-\\x5d\\x80-\\xff]|\\x5c[\\x00-\\x7f])*\\x5d))*(\\.\\w{2,})+$'
const PATTERN_COLISSIMO = '([\\d]{1}[\\w]{1}[\\d]{11})'
const PATTERN_CHRONOPOST = '([\\w]{2}[\\d]{9}[\\w]{2})'
const PATTERN_TRACKING_NUMBER = PATTERN_COLISSIMO + '|' + PATTERN_CHRONOPOST
const PATTERN_ZIP_CODE = '[0-9]{5}'

export class TextField extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = { validate: false }
  }

  componentDidMount() {
    this.setInputValue(this.props.initialValue)
    const { value, valid } = this.getInputState()

    if (this.props.focused) this.inputRef.current.focus()
    if (this.props.validate) this.setState({ validate: true })
    if (this.props.onLoad) this.props.onLoad(value, valid)
  }

  componentDidUpdate(prevProps) {
    const { value } = this.getInputState()

    if (
      prevProps.initialValue !== this.props.initialValue &&
      this.props.initialValue !== value
    ) {
      this.setInputValue(this.props.initialValue)
      this.onChange()
    }

    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate })
    }
  }

  onBlur = () => {
    const { validate } = this.state
    const { value, valid } = this.getInputState()

    if (this.props.autoValidate && !validate) this.setState({ validate: true })
    if (this.props.onBlur) this.props.onBlur(value, valid)
  }

  onChange = () => {
    const { validate } = this.state
    const { value, valid } = this.getInputState()

    if (this.props.onChange) this.props.onChange(value, valid)
    if (this.props.autoValidate && !validate) this.setState({ validate: true })
    this.forceUpdate() // force update as the input state may have changed
  }

  onKeyDown = event => {
    if (event.keyCode === 13) this.onEnter()
  }

  onEnter = () => {
    const { validate } = this.state
    const { value, valid } = this.getInputState()

    if (this.props.autoValidate && !validate) this.setState({ validate: true })
    if (this.props.onEnter) this.props.onEnter(value, valid)
  }

  onWheel = () => {
    // disable number changes while scrolling, due to ux problems
    if (this.props.type === 'number') {
      this.inputRef.current.blur()
    }
  }

  setInputValue = value => {
    this.inputRef.current.value = value
  }

  getInputState = () => {
    if (!this.inputRef.current) return { value: null, valid: null }

    const value = this.inputRef.current.value
    const valid = this.inputRef.current.validity.valid

    return { value, valid }
  }

  reset = () => {
    this.setInputValue('')
    this.onChange()
  }

  render() {
    const { valid } = this.getInputState()
    const { disabled, id, pattern: givenPattern, type, width } = this.props
    let svgColor, pattern, icon
    let modifiers = ''

    if (this.props.light) modifiers += ` ${styles._light}`
    if (!this.props.radiusLeft) modifiers += ` ${styles._noRadiusLeft}`
    if (!this.props.radiusRight) modifiers += ` ${styles._noRadiusRight}`
    if (this.props.resetable) modifiers += ` ${styles._resetable}`
    if (this.props.disabled) modifiers += ` ${styles._disabled}`

    if (this.state.validate) {
      svgColor = valid ? '#00cc67' : '#e40173'
      modifiers += valid ? ` ${styles._valid}` : ` ${styles._invalid}`
    }

    if (givenPattern.length > 0) pattern = givenPattern
    if (type === 'email') pattern = PATTERN_EMAIL
    if (type === 'tel') pattern = PATTERN_PHONE
    if (type === 'tracking_number') pattern = PATTERN_TRACKING_NUMBER
    if (type === 'zip_code') pattern = PATTERN_ZIP_CODE

    if (this.props.icon) {
      modifiers += ` ${styles._icon}`
      icon = React.cloneElement(this.props.icon, { stroke: svgColor })
    }

    return (
      <div className={`${styles.control}${modifiers}`} style={{ width }}>
        {icon && <div className={styles.icon}>{icon}</div>}

        <label className={styles.label}>
          <input
            ref={this.inputRef}
            className={styles.input}
            placeholder=" "
            disabled={disabled}
            required={this.props.required}
            name={id}
            type={type}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            onWheel={this.onWheel}
            pattern={pattern}
          />
          <span className={styles.placeholder}>{this.props.label}</span>

          {this.props.resetable && (
            <button
              className={styles.reset}
              onClick={this.reset}
              title={'Réinitialiser'}
            >
              <IconClose stroke={svgColor} />
            </button>
          )}
        </label>
      </div>
    )
  }
}

TextField.propTypes = {
  autoValidate: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  icon: PropTypes.element,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  light: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  onLoad: PropTypes.func,
  pattern: PropTypes.string,
  radiusLeft: PropTypes.bool,
  radiusRight: PropTypes.bool,
  required: PropTypes.bool,
  resetable: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'number',
    'email',
    'tel',
    'tracking_number',
    'zip_code',
  ]),
  validate: PropTypes.bool,
  width: PropTypes.number,
}

TextField.defaultProps = {
  autoValidate: false,
  disabled: false,
  focused: false,
  icon: null,
  initialValue: '',
  light: false,
  minLength: null,
  maxLength: null,
  min: null,
  max: null,
  step: null,
  onChange: null,
  onBlur: null,
  onEnter: null,
  onLoad: null,
  pattern: '',
  radiusLeft: true,
  radiusRight: true,
  required: false,
  resetable: false,
  type: 'text',
  validate: false,
  width: null,
}
