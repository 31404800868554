import { useCallback, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Annotation,
  Button,
  CustomerBoxCard,
  Divider,
  Grid,
  Heading,
  LayoutFixed,
  MenuPrimary,
  MenuSecondary,
  Subtitle,
  Title,
} from 'ui'
import {
  contactBoxSupplier,
  receiveBoxSupplier,
  reviewBoxSupplier,
  sendBox,
} from 'services/customers'
import { getOrderStatus, updateOrderDetails } from 'services/customers'
import { waitForApi } from 'utils/api'
import { NotificationManager } from 'services/notifications'
import { goToAnchor } from 'utils/route'
import {
  ContactDetails,
  FormContactModal,
  FormReviewModal,
  FormCoordinatesModal,
  ShippingList,
  FormSendModal,
} from '../../@commons'

import styles from './Status.module.scss'

const NOTIF_CHANGE_COORDINATES_SUCCESS = 'Vos coordonnées ont été mises à jour'
const NOTIF_MESSAGE_SUCCESS = 'Votre message a été envoyé'
const NOTIF_REVIEW_SUCCESS = 'Merci pour ce retour précieux !'
const NOTIF_SEND_SUCCESS = 'Votre coffret a été envoyé !'

export const FeatureCustomersOrderStatus = () => {
  const { shopifyId: sId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [links, setLinks] = useState([])
  const [changingDetails, setChangingDetails] = useState(null)
  const [contacting, setContacting] = useState(null)
  const [reviewing, setReviewing] = useState(null)
  const [sending, setSending] = useState(null)

  const shopifyId = parseInt(sId)

  const loadData = useCallback(async () => {
    return waitForApi(getOrderStatus({ id: shopifyId }))
  }, [shopifyId])

  const onLoadDataSuccess = data => {
    setData(data.result)
    setLinks(getMenuLinks(data.result))
    setLoading(false)

    const hash = location.hash
    if (hash.length === 0) navigate(location.pathname + '#resume')
    else goToAnchor(hash.substring(1))
  }

  const onSubmitChangeDetails = details => {
    setLoading(true)
    setChangingDetails(null)

    return waitForApi(updateOrderDetails({ id: shopifyId, details }))
      .then(data => displayActionStatus(data, NOTIF_CHANGE_COORDINATES_SUCCESS))
      .then(loadData)
      .then(onLoadDataSuccess)
      .catch(onError)
  }

  const onSubmitContact = (category, message) => {
    const {
      id: shippingId,
      threadRootKey,
      redeemingId,
      redeemingCode,
    } = contacting
    setLoading(true)
    setContacting(null)

    const data = {
      category,
      message,
      shippingId,
      threadRootKey,
      redeemingId,
      redeemingCode,
    }

    return waitForApi(contactBoxSupplier(data))
      .then(data => displayActionStatus(data, NOTIF_MESSAGE_SUCCESS))
      .then(loadData)
      .then(onLoadDataSuccess)
      .catch(onError)
  }

  const onSubmitReview = (rating, comment) => {
    const { id: shippingId, redeemingId, redeemingCode } = reviewing
    setLoading(true)
    setReviewing(null)

    const data = {
      shippingId,
      comment,
      rating,
      redeemingId,
      redeemingCode,
    }

    return waitForApi(reviewBoxSupplier(data))
      .then(data => displayActionStatus(data, NOTIF_REVIEW_SUCCESS))
      .then(loadData)
      .then(onLoadDataSuccess)
      .catch(onError)
  }

  const onSubmitSend = async details => {
    const { redeemingId, redeemingCode } = sending
    setLoading(true)
    setSending(null)

    const data = {
      shopifyId,
      redeemingId,
      redeemingCode,
      ...details,
    }

    return waitForApi(sendBox(data))
      .then(data => displayActionStatus(data, NOTIF_SEND_SUCCESS))
      .then(() => setLoading(false))
      .catch(onError)
  }

  const displayActionStatus = (data, message) => {
    if (data.exceptions?.length > 0) {
      data.exceptions.map(e => NotificationManager.addError(e))
    } else NotificationManager.addSuccess(message)
    return Promise.resolve(data)
  }

  const onReceived = s => {
    receiveBoxSupplier({
      shippingId: s.id,
      redeemingId: s.redeemingId,
      redeemingCode: s.redeemingCode,
    })
    setReviewing(s)
  }

  const onError = error => {
    setLoading(false)
    setError(error[0])
  }

  const renderModals = () => {
    return (
      <>
        <FormContactModal
          shipping={contacting}
          onCancel={() => setContacting(null)}
          onSubmit={onSubmitContact}
        />
        <FormReviewModal
          shipping={reviewing}
          onCancel={() => setReviewing(null)}
          onSubmit={onSubmitReview}
        />
        <FormCoordinatesModal
          id={changingDetails}
          coords={data?.details}
          onCancel={() => setChangingDetails(null)}
          onSubmit={onSubmitChangeDetails}
        />
        <FormSendModal
          box={sending}
          onCancel={() => setSending(null)}
          onSubmit={onSubmitSend}
        />
      </>
    )
  }

  return (
    <LayoutFixed
      title="Suivi de commande"
      nav={<MenuPrimary title={'Suivi de commande'} />}
      menu={<MenuSecondary links={links} />}
      loading={loading}
      error={error}
      loadData={loadData}
      onDataLoaded={onLoadDataSuccess}
      offscreen={renderModals()}
    >
      <div>
        <Title level={1}> Merci d'avoir choisi Ici Présent!</Title>

        {data?.boxes?.length > 0 && (
          <div>
            <Divider />
            <div id={'boxes'}>
              <Subtitle level={2}>Vos coffret-cadeaux</Subtitle>
              <Divider small />

              <Annotation>
                Vous trouverez ci-dessous les boutons de téléchargement pour vos
                coffrets.
              </Annotation>
              <Annotation>
                Note : la préparation d'un coffret peut prendre quelques
                secondes.
              </Annotation>
              <Divider />
              <Grid
                cols={1}
                items={data.boxes.map((b, i) => (
                  <div key={i}>
                    <CustomerBoxCard
                      box={b}
                      shopifyId={shopifyId}
                      onSend={() => setSending(b)}
                    />
                  </div>
                ))}
              />
            </div>
            <Divider />
          </div>
        )}
      </div>

      {data?.shippings?.length > 0 && (
        <div>
          <div id={'shippings'}>
            <Divider />
            <Subtitle level={2}>Vos achats en direct</Subtitle>

            {data.details && (
              <div>
                <div className={styles.detailsHeading}>
                  <div id={'coords'}>
                    <Divider />
                    <Heading level={3}>Vos coordonnées</Heading>
                  </div>
                  {data.canChangeDetails && (
                    <div>
                      <Button
                        small
                        onClick={() => setChangingDetails(shopifyId)}
                      >
                        Changer
                      </Button>
                    </div>
                  )}
                </div>
                <Divider small />
                <ContactDetails details={data.details} />
              </div>
            )}

            <ShippingList
              shippings={data.shippings}
              onContact={setContacting}
              onReceived={onReceived}
              onReview={setReviewing}
            />
          </div>
        </div>
      )}
    </LayoutFixed>
  )
}

const getMenuLinks = result => {
  const links = []

  if (result.boxes.length) {
    links.push({ to: `#boxes`, label: 'Coffret-cadeaux', primary: true })
  }

  if (result.shippings.length) {
    links.push({ to: `#shippings`, label: 'Achats en directs', primary: true })
    links.push({ to: `#coords`, label: 'Vos coordonnées' })

    result.shippings.forEach(s =>
      links.push({ to: `#${s.id}`, label: s.supplierName })
    )
  }
  return links
}
