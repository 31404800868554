import { useState } from 'react'
import { Button, Divider, Modal, Selectbox, TextArea } from 'ui'

const categoryOptions = [
  "Demande d'information générale",
  'Problème de livraison',
  "Changement d'adresse",
  'Autre',
]

export const FormContactModal = ({ shipping, onCancel, onSubmit }) => {
  const [message, setMessage] = useState('')
  const [category, setCategory] = useState(null)

  const cancel = () => {
    onCancel()
    setMessage('')
    setCategory(null)
  }

  const submit = () => {
    onSubmit(category, message)
    setMessage('')
    setCategory(null)
  }

  const askCategory = !shipping?.messages || shipping.messages.length === 0
  const isValid = askCategory
    ? message !== '' && category !== null
    : message !== ''

  return (
    <Modal
      opened={!!shipping}
      title={`Contacter ${shipping ? shipping.supplierName : ''}`}
      closable
      leftActions={[<Button onClick={cancel}>Annuler</Button>]}
      actions={[
        <Button primary disabled={!isValid} onClick={submit}>
          Envoyer
        </Button>,
      ]}
      onClose={cancel}
    >
      {shipping && (
        <>
          {askCategory && (
            <div>
              <Selectbox
                id={'Category'}
                label={'Catégorie'}
                values={categoryOptions}
                required
                initialValue={category}
                onChange={setCategory}
              />
              <Divider />
            </div>
          )}
          <TextArea
            id={'Message'}
            label={`Votre message`}
            rows={6}
            autoResize
            required
            initialValue={message}
            onChange={setMessage}
          />
        </>
      )}
    </Modal>
  )
}
